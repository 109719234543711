import MicroModal from 'micromodal';

window.addEventListener('DOMContentLoaded', (event) => {
  let key = 'redirectModal';
  let url = window.location.href;
  if (!localStorage.getItem(key) && !url.includes('?')) {
    MicroModal.init();
    MicroModal.show('redirect-modal');
    localStorage.setItem(key, 'opened');
  }
});
