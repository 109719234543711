import GoogleMapsLoader from 'google-maps'

window.addEventListener('DOMContentLoaded', (e) => {
  var sellersMap = document.getElementById('seller-map');

  if (sellersMap != null) {
    var pickupLatitude = parseFloat(sellersMap.getAttribute('data-latitude'))
    var pickuplongitude = parseFloat(sellersMap.getAttribute('data-longitude'))
  }

  var Map = {
    $mapElement: document.getElementById('seller-map'),
    address: { lat: pickupLatitude, lng: pickuplongitude },
    mapcenter: { lat: pickupLatitude, lng: pickuplongitude },
    map: false,
    markers: [],
    infowindows: [],

    init: function () {
      if (Map.$mapElement) {
        Map.bindEvents()
      }
    },

    loadMap: function (google) {
      var styleArray = [{
        stylers: [{
          gamma: 2.42
        }, {
          saturation: -58
        }, {
          hue: '#7762FC'
        }, {
          lightness: -5
        }]
      }, {
        featureType: 'road.highway',
        stylers: [{
          saturation: -62
        }, {
          lightness: -2
        }]
      }, {
        featureType: 'poi',
        stylers: [{
          visibility: 'simplified'
        }]
      }, {
        featureType: 'water',
        stylers: [{
          visibility: 'on'
        }, {
          saturation: -67
        }, {
          lightness: -2
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }]

      var options = {
        center: Map.mapcenter,
        scrollwheel: false,
        mapTypeControl: true,
        overviewMapControl: true,
        streetViewControl: true,
        zoom: parseFloat(Map.$mapElement.getAttribute('data-zoom')),
        styles: styleArray
      }

      return new google.maps.Map(Map.$mapElement, options)
    },

    displaySellersLocation: function() {
      var marker, i

      var infowindow = new google.maps.InfoWindow
      var icon = {
        url: Map.$mapElement.getAttribute('data-pin'),
        size: new google.maps.Size(18, 25),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(9, 50),
        scaledSize: new google.maps.Size(18, 25)
      }

      for (i = 0; i < sellersLocations.length; i++) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(sellersLocations[i].latitude, sellersLocations[i].longitude),
          icon: icon,
          map: Map.map
        })

        google.maps.event.addListener(marker, 'click', (function(marker, i) {
          return function () {
            infowindow.setContent('<h3 class="font-size-m mb-xs">' + sellersLocations[i].marketplace_profile_name + '</h3>' +
              '<div><a href="https://hektarnektar.com/de/marktplatz/suchergebnis?s=' + encodeURI(sellersLocations[i].marketplace_profile_name) + '" target="_blank">Verkäuferprodukte</a></div>'
            )
            infowindow.open(Map.map, marker)
          }
        })(marker, i))
      }

      marker.setMap(Map.map)
    },

    bindEvents: function () {
      GoogleMapsLoader.KEY = Map.$mapElement.getAttribute('data-key'),
      GoogleMapsLoader.LANGUAGE = Map.$mapElement.getAttribute('data-lang'),
      GoogleMapsLoader.REGION = Map.$mapElement.getAttribute('data-lang'),
      GoogleMapsLoader.load(Map.events.onMapLoad)
    },

    events: {
      onMapLoad: function (google) {
        Map.map = Map.loadMap(google)
        Map.displaySellersLocation()
      }
    }
  }

  if (sellersMap != null) {
    var mapa = new Map.init
  }
})
